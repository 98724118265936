import { PLATFORM_ROUTES } from '@frontend/routing';
import {
  Navigate,
  Outlet,
  useLocation,
  useMatch,
  useSearchParams,
  matchPath,
} from 'react-router-dom';
import useAuth from '../AuthContext/useAuth';
import PlatformHelmet from '../PlatformHelmet/PlatformHelmet';

const PublicRoute = () => {
  const { currentUser, isCurrentUserBuyer, isCurrentUserSeller } = useAuth();
  const location = useLocation();
  const matchesEmailVerificationPath = useMatch(
    PLATFORM_ROUTES.emailVerification,
  );
  const [searchParams] = useSearchParams();
  const redirectUrl: string = searchParams.get('redirectUrl') ?? '';

  const getDestinationPath = (unsafeRedirectUrl: string) => {
    let safeRedirectUrl = unsafeRedirectUrl;
    if (
      !isCurrentUserBuyer &&
      matchPath(PLATFORM_ROUTES.buyerDashboardAll, safeRedirectUrl)
    ) {
      safeRedirectUrl = PLATFORM_ROUTES.sellerDashboard;
    }
    if (
      !isCurrentUserSeller &&
      matchPath(PLATFORM_ROUTES.sellerDashboardAll, safeRedirectUrl)
    ) {
      safeRedirectUrl = PLATFORM_ROUTES.buyerDashboard;
    }
    return safeRedirectUrl
      ? safeRedirectUrl
      : isCurrentUserBuyer
        ? PLATFORM_ROUTES.buyerDashboard
        : PLATFORM_ROUTES.sellerDashboard;
  };

  if (currentUser && !matchesEmailVerificationPath) {
    return (
      <Navigate
        to={getDestinationPath(redirectUrl)}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <>
      <PlatformHelmet />
      <Outlet />
    </>
  );
};

export default PublicRoute;
