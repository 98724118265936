import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { USER_ROLE_TYPE_ENUM } from '@frontend/api';
import { ProtectedRoute, PublicRoute } from '@frontend/auth';
import { PLATFORM_ROUTES } from '@frontend/routing';
import {
  addCookieRefreshListeners,
  removeCookieRefreshListeners,
  useScrollTracking,
} from '@frontend/tracking';
import { usePageView } from './usePageView';
import BiddableListingPage from '../pages/BiddableListingPage/BiddableListingPage';
import BuyMetal from '../pages/BuyMetal/BuyMetal';
import BuyerDashboard from '../pages/BuyerDashboard/BuyerDashboard';
import LoginValidation from '../pages/LoginValidation/LoginValidation';
import SellMetal from '../pages/SellMetal/SellMetal';
import RequestDetails from '../pages/BuyerDashboard/RequestDetails/RequestDetails';
import SellerDashboard from '../pages/SellerDashboard/SellerDashboard';
import EmailUnsubscription from '../pages/EmailUnsubscription/EmailUnsubscription';
import EmailVerification from '../pages/EmailVerification/EmailVerification';
import ListingDetails from '../pages/SellerDashboard/ListingDetails/ListingDetails';
import AcceptWhatsappConsent from '../pages/AcceptWhatsappConsent/AcceptWhatsappConsent';

const Router = () => {
  useEffect(() => {
    addCookieRefreshListeners();
    removeCookieRefreshListeners();
  }, []);

  usePageView();
  useScrollTracking();

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route
          path={PLATFORM_ROUTES.loginValidation}
          element={<LoginValidation />}
        />
        <Route
          path={PLATFORM_ROUTES.emailVerification}
          element={<EmailVerification />}
        />
        <Route path={PLATFORM_ROUTES.buyMetal} element={<BuyMetal />} />
        <Route path={PLATFORM_ROUTES.sellMetal} element={<SellMetal />} />
      </Route>

      <Route
        element={
          <ProtectedRoute
            fallbackUrl={PLATFORM_ROUTES.buyMetal}
            allowedRoles={[
              USER_ROLE_TYPE_ENUM.BUYER,
              USER_ROLE_TYPE_ENUM.SELLER_AND_BUYER,
            ]}
          />
        }
      >
        <Route path={PLATFORM_ROUTES.buyerDashboard}>
          <Route index element={<BuyerDashboard />} />
          <Route
            path={PLATFORM_ROUTES.requestInfo}
            element={<RequestDetails />}
          >
            <Route path={PLATFORM_ROUTES.requestDetails} element={<Outlet />}>
              <Route path={PLATFORM_ROUTES.orderDetails} element={<Outlet />}>
                <Route
                  path={PLATFORM_ROUTES.updateBillOfLading}
                  element={<Outlet />}
                />
                <Route
                  path={PLATFORM_ROUTES.containerDetails}
                  element={<Outlet />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute
            fallbackUrl={PLATFORM_ROUTES.sellMetal}
            allowedRoles={[
              USER_ROLE_TYPE_ENUM.SELLER,
              USER_ROLE_TYPE_ENUM.SELLER_AND_BUYER,
            ]}
          />
        }
      >
        <Route path={PLATFORM_ROUTES.sellerDashboard}>
          <Route index element={<SellerDashboard />} />
          <Route
            path={PLATFORM_ROUTES.listingInfo}
            element={<ListingDetails />}
          >
            <Route path={PLATFORM_ROUTES.listingDetails} element={<Outlet />}>
              <Route
                path={PLATFORM_ROUTES.listingOrderDetails}
                element={<Outlet />}
              >
                <Route
                  path={PLATFORM_ROUTES.listingOrderPhotos}
                  element={<Outlet />}
                />
                <Route
                  path={PLATFORM_ROUTES.listingOrderDocuments}
                  element={<Outlet />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute
            fallbackUrl={PLATFORM_ROUTES.buyMetal}
            allowedRoles={[
              USER_ROLE_TYPE_ENUM.BUYER,
              USER_ROLE_TYPE_ENUM.SELLER,
              USER_ROLE_TYPE_ENUM.SELLER_AND_BUYER,
            ]}
          />
        }
      >
        <Route
          path={PLATFORM_ROUTES.acceptWhatsappConsent}
          element={<AcceptWhatsappConsent />}
        />
      </Route>

      <Route
        path={PLATFORM_ROUTES.biddableListing}
        element={<BiddableListingPage />}
      />
      <Route
        path={PLATFORM_ROUTES.emailUnsubscription}
        element={<EmailUnsubscription />}
      />
      <Route
        path={'*'}
        element={<Navigate to={PLATFORM_ROUTES.buyMetal} replace />}
      />
    </Routes>
  );
};
export default Router;
